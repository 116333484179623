<template>
  <div class="home_wrap">
    <!-- 头部 -->
    <div class="header_wrap flex f_j_between f_a_center">
      <div class="title">{{title}}</div>
      <div class="btns flex">
        <div class="list_btn flex f_a_center f_j_center" @click="isInfoList = !isInfoList">
          <img src="../../assets/image/table2@2x.png" alt="" />
          <span @click="jump()">采集列表</span>
        </div>
        <div class="download_btn flex f_a_center f_j_center">
          <img src="../../assets/image/download@2x.png" alt="" />
          <span>批量下载</span>
        </div>
        <div class="download_btn1 flex f_a_center f_j_center" @click="showUpload =true">
          <i class="el-icon-upload2 icon-upload"></i>
          <!-- <img src="../../assets/image/table2@2x.png" alt="" /> -->
          <span>上传模板</span>
        </div>
        <div class="download_btn flex f_a_center f_j_center" @click="downDemo()" >
          <img src="../../assets/image/download@2x.png" alt="" />
          <span>下载模板</span>
        </div>
      </div>
    </div>
    <!-- 有数据的情况下 -->
    <template v-if="tableData.length || isSearch == true">
      <!-- 筛选 -->
      <el-row class="row flex " :gutter="20">
        <el-col class="flex" :span="3">
          <el-select v-model="select" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-col>
        <!-- 自定义收集信息类型 -->
        <el-col class="flex" :span="3" v-for="v,k in params">
          <el-input v-model="keyword[k]" :placeholder="v.value"></el-input>
        </el-col>
        <el-col class="flex" :span="3">
          <el-button type="primary" icon="el-icon-search" style="background:#4D96FF" @click="heardSearch()">搜索</el-button>
        </el-col>
      </el-row>

      <!-- table -->
      <el-table :data="tableData" stripe border style="width: 99%">
        <el-table-column type="index" label="序号" min-width="50" align="center"> </el-table-column>
        <el-table-column prop="address" :label="v.value" align="center" min-width="100" v-for="v,k in params">
          <template slot-scope="scoped">
            <p>{{scoped.row[k]}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="审核状态" align="center" min-width="80">
          <template slot-scope="scoped">
            <div class="wrap flex f_a_center f_j_center">
              <div class="wait_examine" v-if="scoped.row.collect_status == '0'">待审核</div>
              <div class="pass_examine" v-if="scoped.row.collect_status == '1'">审核通过</div>
              <div class="no_collect" v-if="!scoped.row.identity_form_id">未采集</div>
              <div class="back_examine" v-if="scoped.row.collect_status == '-1'">驳回重拍</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" min-width="220">
          <template slot-scope="scoped">
            <div class="btns_wrap flex f_a_center f_j_center">
              <!-- <el-button type="warning" size="small" @click="back(scoped.row)" v-if="scoped.row.collect_status == '1'" >驳回</el-button> -->
              <el-button type="danger" size="small" @click="del(scoped.row)">删除</el-button>
              <el-button type="primary" size="small"  @click="edit(scoped.row)">修改</el-button>
              <!-- <div class="examine" @click="back(scoped.row.collect_id)">驳回</div>
              <div class="delete" @click="del(scoped.row)">删除</div>
              <div class="edit" @click="edit(scoped.row)">修改 </div> -->
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 100, 1000]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>

    </template>

    <!-- 没有数据的时候 -->
    <template v-else>
      <div class="nav_list flex f_a_center">
        <div class="download f_c flex f_a_center f_j_center" @click="downDemo()">
          <img class="icon" src="@/assets/image/icon_1.png" />
          <div class="text">下载采集模板</div>
        </div>
        <div class="upload f_c flex f_a_center f_j_center" @click="showUpload =true">
          <img class="icon" src="@/assets/image/icon_2.png" />
          <div class="text">上传采集模板</div>
        </div>
      </div>
    </template>

    <!-- 上传文件 -->
    <el-dialog
      :visible.sync="showUpload"
      :close-on-click-modal="false"
      @closed="clearfileList()"
      top="30vh"
      width="30%">
      <el-upload
        class="upload-demo"
        :action="baseUrl+'/Admin_Task/upload'"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :limit="1"
        :http-request="uploade"
        :on-exceed="handleExceed"
        accept=".xlsx"
        :file-list="fileList">
        <el-button size="small">选择文件上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传xlsx文件，且不超过500kb</div>
      </el-upload>
    </el-dialog>

    <!-- 修改页 -->
    <el-dialog
      :visible.sync="isShowEdit"
      :close-on-click-modal="false"
      @closed="clearEdit()"
      width="30%">
      <el-form ref="form" :model="editData" label-width="80px">
        <el-form-item :label="v.value" v-for="v,k in params">
          <el-input v-model="editData[k]"></el-input>
        </el-form-item>
        <el-form-item >
          <el-button type="primary" @click="submitEdit(editData)">修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 驳回页 -->
    <el-dialog
      :visible.sync="isShowBack"
      :close-on-click-modal="false"
      @closed="clearBack()"
      width="30%">
      <el-form ref="form" label-width="80px">
        <el-radio-group v-model="backStr">
          <el-row class="row flex " :gutter="20" v-for="v,k in backDemo">
            <el-col class="flex" :span="5">
            </el-col>
            <el-col class="flex" :span="15">
              <el-radio :label="v" ></el-radio>
            </el-col>
          </el-row>
          <el-row class="row flex " :gutter="20">
            <el-col class="flex" :span="5">
            </el-col>
            <el-col class="flex" :span="15">
              <el-radio label="其他原因" ></el-radio>
            </el-col>
          </el-row>
        </el-radio-group>
        <el-row class="row flex " :gutter="20">
          <el-col class="flex" :span="3">
          </el-col>
          <el-col class="flex" :span="18">
             <el-input type="textarea" v-model="backElseStr" role="3"></el-input>
          </el-col>
        </el-row>
        <el-form-item >
          <el-button type="primary" @click="submitBack()">驳回</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--删除页 -->
    <el-dialog
      :visible.sync="isShowDel"
      :close-on-click-modal="false"
      @closed="clearDel()"
      width="30%">
      <el-form ref="form" label-width="80px">
        <el-row class="row flex " :gutter="20">
          <el-col class="flex" :span="3">
          </el-col>
          <el-col class="flex" :span="18">
             <p>确定删除此数据吗?</p>
          </el-col>
        </el-row>
        <el-form-item >
          <el-button type="danger" @click="submitDel()">删除</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { baseUrl } from "@/config";
import { Loading, Message , Popconfirm } from "element-ui";
export default {
  name: "home",
  data() {
    return {
      isSearch: false,
      isShowEdit:false,
      isShowDel:false,
      editData:{
        key1:'',
        key2:'',
        key3:'',
        key4:'',
        key5:'',
        key6:'',
        task_id : '',
        task_qrcode_id : '',
        identity_id : '',
      },
      isShowBack:false,
      backDemo:[
        '清晰度低,请用前置摄像头重拍',
        '背景复杂,请选择白色背景重拍',
        '装容不整齐,请梳理后重拍',
        '光纤不足,请到光线明亮处重拍',
      ],
      backElseStr:'',
      backStr:'',
      backId:'',
      delID:'',
      no_upload: require("@/assets/image/no_upload@2x.png"),
      baseUrl:baseUrl, // 引入baseUrl
      fileList:[], // 导入数据文件列表
      id:'', // taks_id 或者 task_qrcode_id
      title:'', // 标题
      page:1, // 页数
      limit:10, // 每页显示数量
      total:0, // 每页显示数量
      showUpload:false, // 是否显示上传文件弹窗
      isInfoList:false, // 是否显示信息列表
      options: [ // 状态
        {
          value: 'all',
          label: "所有"
        },
        {
          value: "pass",
          label: "通过"
        },
        {
          value: "no_pass",
          label: "未通过"
        },
        {
          value: "reject",
          label: "驳回"
        },
        {
          value: "no",
          label: "未采集"
        },
      ],
      value: "",
      select: "all", // 默认选中项
      params: [], // 采集信息中的自动字段
      keyword:[],
      tableInfoData:[ // 待采集信息数据
        {
          date: "2016-05-02"
        },
        {
          date: "2016-05-04"
        },
        {
          date: "2016-05-01"
        },
        {
          date: "2016-05-03"
        }
      ],
      tableData: [  // 采集列表数据
      ],
    }
  },
  watch: {
    '$route': 'initData'
  },
  created() {
    this.id =  this.$route.query.id
    this.title =  this.$route.query.title
    if (this.id){
      this.getCollectList()
      this.getTaskDetail()
    }
    
  },
  methods: {
    jump(){
      this.$router.push({path:'/home',query: {id:this.id,title:this.title}})
    },
    // 初始化数据
    initData(){
      console.log(this.$route.query);
      this.id =  this.$route.query.id
      this.title =  this.$route.query.title
      this.clearKeyword()
      this.clearPage()
      this.getCollectList()
      this.getTaskDetail()
    },
    // 当页数发生改变
    handleCurrentChange(val){
      this.page = val
      this.getCollectList()
    },
    // 每页显示数量改变
    handleSizeChange(val) {
      this.limit = val
      this.getCollectList()
    },
    // 头部点击搜索
    heardSearch(){
      this.isSearch = true
      this.clearPage()
      this.getCollectList()
    },
    // 获取收集列表
    getCollectList(){
      let paramSelect = {}
      paramSelect.id = this.id
      paramSelect.page = this.page
      paramSelect.limit = this.limit
      paramSelect.status = this.select
      // 自定义信息组装查询
      for (let k in this.keyword){
        paramSelect[k] = this.keyword[k]
      }
      
      this.$api.task.idFromList(paramSelect).then(res => {
        if (res.data){
          console.log(res.data);
          this.total = res.data.count
          this.tableData = res.data.list
        }
      })
    },
    // 获取采集任务详情
    getTaskDetail(){
      this.$api.task.taskDetails({id:this.id}).then(res => {
        if (res.data){
          if (res.data.is_collect_identity == 1){
            this.params = res.data.id_form_json
          }
        }
      })
    },
    // 清除搜索数据
    clearKeyword(){
      this.params = []
      this.keyword = []
      this.select = 'all'
      this.isSearch = false
    },
    // 重置分页
    clearPage(){
      this.page = 1
    },
    // 下载demo表格
    downDemo(){
      this.$api.task.excelDemo({id:this.id}).then(res => {
        if (res.data){
          this.downFile(res.data)
        }
      })
    },
    downFile(url){
        let a = document.createElement('a')
        a.href = url
        a.click();
    },
    // 导入数据
    uploade(param){
      const self = this;
      //file就是当前添加的文件
      var fileObj = param.file;
      // FormData 对象
      var form = new FormData();
      // 文件对象,key是后台接受的参数名称
      form.append("file", fileObj);
      form.append("id", this.id);
      this.$api.task.excelUpload(form).then(res => {
        if (res.data == true){
          this.clearfileList()
        }
      })
    },
    clearfileList(){
      this.fileList = []
    },
    clearEdit(){
      this.editData = {
        key1:'',
        key2:'',
        key3:'',
        key4:'',
        key5:'',
        key6:'',
        task_id : '',
        task_qrcode_id : '',
        identity_id : '',
      }
    },
    clearBack(){
      this.backStr = ''
      this.backElseStr = ''
      this.backId = ''
    },
    clearDel(){
      this.delID = ''
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${ file.name }？`);
    },
    back(data) {
      this.isShowBack = true
      this.backId = data.collect_id
    },
    del(data){
      this.isShowDel = true
      this.delID = data.id
    },
    submitDel(){
      let param = {}
      param.id = this.delID
      if (!param.id){
        Message({
            message: '数据异常',
            type: "error",
        });
        return false
      } 
      this.$api.task.delIdFrom(param).then(res => {
        if (res.data){
          this.$message.success('删除成功')
          this.getCollectList()
          this.clearDel()
          this.isShowDel = false
        }
      })
    },
    edit(data){
      for (let k in this.params){
        this.editData[k] = data[k]
      }
      this.editData.task_id = data.task_id
      this.editData.task_qrcode_id = data.task_qrcode_id
      this.editData.identity_id = data.id
      this.isShowEdit = true
    },
    submitEdit(data){
      let param = {}
      param.task_id = data.task_id
      param.task_qrcode_id = data.task_qrcode_id
      param.identity_id = data.identity_id
      param.param_json = {}
      for (let k in this.params){
        param.param_json[k] = data[k]
      }
      if (!param.task_id || !param.task_qrcode_id || !param.identity_id){
        Message({
            message: '数据异常',
            type: "error",
        });
        return false
      }
      this.$api.task.editIdFrom(param).then(res => {
        if (res.data){
          this.$message.success('修改成功')
          this.getCollectList()
          this.clearEdit()
          this.isShowEdit = false
        }
      })
    },
    submitBack(){
      let param = {}
      param.collect_log_id = this.backId
      param.rejection_reson = this.backStr
      if (!param.rejection_reson){
        param.rejection_reson = this.backElseStr
      }
      if (!param.collect_log_id || !param.rejection_reson){
        Message({
            message: '请选择驳回数据',
            type: "error",
        });
        return false
      }
      this.$api.task.rejectCollectLog(param).then(res => {
        if (res.data){
          this.$message.success('修改成功')
          this.getCollectList()
          this.clearBack()
          this.isShowBack = false
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.home_wrap {
  padding: 24px 32px;
  background-color: #fff;
  box-sizing: border-box;
  .header_wrap {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    .title {
      color: #262829;
      font-size: 20px;
    }

    .btns {
      .list_btn {
        width: 100px;
        height: 32px;
        padding: 0 12px;
        border: 1px solid #4d96ff;
        border-radius: 4px;
        cursor: pointer;

        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }

        span {
          color: #4d96ff;
          font-size: 14px;
        }
      }

      .download_btn {
        width: 100px;
        height: 32px;
        padding: 0 12px;
        margin-left: 11px;
        background-color: #4d96ff;
        border-radius: 4px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }

        span {
          color: #ffffff;
          font-size: 14px;
        }
      }
      .download_btn1{
        width: 100px;
        height: 32px;
        padding: 0 12px;
        margin-left: 11px;
        border: 1px solid #4d96ff;
        border-radius: 4px;
        cursor: pointer;
        .el-icon-upload2 {
          font-size: 19px;
          font-weight: 600;
          color: #4d96ff;
        }

        span {
          color: #4d96ff;
          font-size: 14px;
        }
      }
    }
  }
  .row {
    margin: 24px 0;
  }

  .photo_wrap {
    width: 80px;
    height: 108px;
    border-radius: 4px;
    position: relative;
    .sample {
      width: 100%;
      height: 100%;
    }

    .btns {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 80px;
      height: 16px;
      opacity: 0.6;
      border-radius: 0 0 4px 4px;
      padding: 0 12px;
      background-color: #000000;
      .back {
        width: 12px;
        height: 12px;
      }
      .download {
        width: 12px;
        height: 12px;
      }
    }
  }

  .info_wrap {
    text-align: left;
    color: #606466;
    font-size: 14px;
  }

  .wrap {
    width: 100%;
    .wait_examine {
      color: #f2994a;
      font-size: 14px;
    }

    .pass_examine {
      color: #27ae60;
      font-size: 14px;
    }

    .no_collect {
      color: #9da1a3;
      font-size: 14px;
    }

    .back_examine {
      color: #eb5757;
      font-size: 14px;
    }
  }

  .btns_wrap {
    width: 100%;
    .examine,
    .edit,
    .delete {
      width: 60px;
      height: 32px;
      border-radius: 2px;
      border: 1px solid #e0e0e0;
      color: #606466;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      margin-left: 12px;
    }

    .examine {
      margin-left: 0;
    }
  }

  .nav_list {
    margin-top: 32px;
    .download {
      width: 240px;
      height: 240px;
      border-radius: 8px;
      background-color: #fafafa;
      border: 1px dashed #9da1a3;
      .icon {
        width: 60px;
        height: 60px;
      }

      .text {
        color: #606466;
        font-size: 14px;
        margin-top: 16px;
      }
    }

    .upload {
      width: 240px;
      height: 240px;
      border-radius: 8px;
      background-color: #fafafa;
      margin-left: 32px;
      border: 1px dashed #9da1a3;
      .icon {
        width: 60px;
        height: 60px;
      }

      .text {
        color: #606466;
        font-size: 14px;
        margin-top: 16px;
      }
    }
  }
}
.el-pagination{
  text-align: center;
}
/deep/.el-table--border td {
  border-right: 1px solid #ededed !important;
  border-bottom: 1px solid #ededed !important;
}
/deep/.el-table--border th {
  border-right: 1px solid #ededed !important;
  border-bottom: 1px solid #ededed !important;
}
</style>
